
<template>
  <div>
    <super-header />

    <div class="directories">
      <div v-for="directory in directories" :key="directory.id">
        <h1>{{ directory.name }}</h1>
        <div><router-link class="action-btn" :to="'/super/listings/create/' + directory.slug">Create New Listing</router-link></div>
        <div><a class="action-btn" :href="ENV_URL + '/super/listings/csv/' + directory.slug" target="_blank">Export Listings to CSV</a></div>
      </div>
    </div>
  </div>
</template>
<script>
import SuperHeader from 'components/super/SuperHeader.vue';
import {ENV_URL} from '../../environment.js';
import axios from 'axios';
export default {

  name: 'directory-view',

  components: {
    SuperHeader,
  },
  
  data () {
    return {
      ENV_URL: ENV_URL,
      directories: [],
    }

  },

  created() {
    const uri = ENV_URL + '/super/directories';
    axios.get(uri)
    .then((response) => {
      this.directories = response.data.directories;
    })
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted() {
    },
  },
}
</script>

<style lang="scss">

</style>
